<template>
  <div class="card">
    <Toast position="top-center" group="info" />
    <h5>Errors logs</h5>
    <ProgressBar
      v-if="loading"
      class="progress-bar"
      mode="indeterminate"
    />
    <div v-show='!loading'>
      <DataTable
        v-if="errors?.length"
        :value="errors"
        data-key="id"
        paginator
        lazy
        :rows="itemsPerPage"
        :totalRecords="totalItems"
        @page="setPage"
      >
        <Column header="User" field="email">
          <template #body="slot">
            <span
              class="clickable"
              @click="goToUser(slot.data?.userId)"
            >
              {{ slot.data?.email }}
            </span>
          </template>
        </Column>
        <Column header="Project" field="project">
          <template #body="slot">
            {{ slot.data?.project }}
          </template>
        </Column>
        <Column header="Amount" field="value">
          <template #body="slot">
            <OutputDecimals
              :decimals-value="slot.data?.value"
              :decimals="slot.data?.decimals"
            />
          </template>
        </Column>
        <Column header="Event description" field="description">
          <template #body="slot">
            {{ slot.data?.description }}
          </template>
        </Column>
        <Column header="Date" field="createdAt">
          <template #body="slot">
            {{ getDate(slot.data?.createdAt) }}
          </template>
        </Column>
        <Column header="Error message" field="msg">
          <template #body="slot">
            {{ formattedMsg(slot.data?.msg) }}
          </template>
        </Column>
        <Column class="bg-white border-none">
          <template #body="slot">
            <div data-help="Show">
              <Button
                icon="pi pi-info-circle"
                class="p-button-rounded p-button-warning"
                @click="showMsgDialog(slot.data?.msg)"
              />
            </div>
          </template>
        </Column>
      </DataTable>
      <div v-else>
        <p>Not any errors yet</p>
      </div>
    </div>
  </div>
  <Dialog
    v-model:visible="msgDialog"
    header="Error message"
  >
    <div class="dialog-content">
      <Textarea
        v-model=msg
        cols="80"
        rows="10"
        readonly
      />
      <Button
        label="Copy to clipboard"
        class="p-button-primary w-6"
        @click="copyToClipboard"
      />
    </div>
  </Dialog>
</template>

<script>
import OutputDecimals from '@/components/edit/OutputDecimals'
import moment from 'moment'
export default {
  name: "Errors",
  components: { OutputDecimals },
  data() {
    return {
      loading: false,
      page: 1,
      itemsPerPage: 30,
      errors: [],
      totalItems: 0,
      msg: null,
      msgDialog: false
    };
  },
  async mounted() {
    await this.getErrors();
  },
  methods: {
    async getErrors() {
      this.loading = true;
      const headers = {
        accept: "application/ld+json",
        "Content-Type": "application/ld+json"
      };
      const params = {
        page: this.page,
        itemsPerPage: this.itemsPerPage
      };
      try {
        const resp = await this.axios("/api/logs", { headers, params });
        this.errors = resp.data['hydra:member'];
        this.totalItems = resp.data['hydra:totalItems'];

        for (const item of this.errors) {
          if (item.msg[0] === "{") {
            item.msg = JSON.parse(item.msg)?.message ?? item.msg;
          }
          const buf = await this.getTokenEventInfo(item.tokenEvent);
          for (const key in buf) {
            item[key] = buf[key];
          }
        }
      }
      catch (e) {
        this.$toast.add({
          severity: "error",
          summary: "Error in /logs api call's",
          detail: "Error in ",
          group: "info",
          life: 3000
        });
      }
      this.loading = false;
    },

    async getTokenEventInfo(iri) {
      let ret = {};
      const id = iri.slice(iri.lastIndexOf("/") + 1);
      try {
        const resp = await this.axios.get(`/api/token_events/${id}`);
        ret = {
          project: resp.data?.auCapital?.tokenBase?.nameEng ?? "-",
          userId: resp.data?.user?.id,
          email: resp.data?.user?.email,
          value: resp.data?.value,
          decimals: resp.data?.token?.decimals,
          description: resp.data?.description
        };
      }
      catch (e) {
        this.$toast.add({
          severity: "error",
          summary: "Error in /token_events api call's",
          detail: "Error in ",
          group: "info",
          life: 3000
        });
      }
      return ret;
    },

    goToUser(id) {
      this.$router.push({ name: "MarketingUsers", params: { id: id }});
    },

    getDate(date) {
      return moment(date).format("HH:mm:ss DD.MM.YYYY");
    },

    formattedMsg(text) {
      return text.length < 200 ? text : text.slice(0, 200) + "...";
    },

    copyToClipboard() {
      navigator.clipboard.writeText(this.msg);
    },

    showMsgDialog(msg) {
      this.msg = msg;
      this.msgDialog = true;
    },

    async setPage(event) {
      this.page = event.page + 1;
      await this.getErrors();
    },
  }
}
</script>

<style scoped>
[data-help] {
  position: relative;
}
[data-help]::after {
  content: attr(data-help);
  position: absolute;
  left: -5px;
  top: 0;
  pointer-events: none;
  opacity: 0;
  transition: 0.5s;
}
[data-help]:hover::after {
  opacity: 1;
  top: -1.5em;
}
.dialog-content {
  display: flex;
  flex-direction:column;
  align-items:center;
  gap: 20px;
}
</style>