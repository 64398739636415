import detectEthereumProvider from '@metamask/detect-provider'

export default {
  data() {
    return {
      provider: null,
      chainId: null,
      accountAddress: null,
      web3: {},
      mmInstalled: false,
    }
  },
  async mounted() {
    await this.startMetamask()
  },
  methods: {
    async startMetamask() {
      // this returns the provider, or null if it wasn't detected
      const provider = await detectEthereumProvider()
      if (provider) {
        if (provider !== window.ethereum) {
          console.error('Do you have multiple wallets installed?')
          return
        }
        this.provider = provider
        this.chainId = await provider.request({ method: 'eth_chainId' }) // 0x61

        provider
          .request({ method: 'eth_accounts' })
          .then(this.metamaskEventAccountsChanged.bind(this))
          .catch((err) => {
            // Some unexpected error.
            // For backwards compatibility reasons, if no accounts are available,
            // eth_accounts will return an empty array.
            console.error(err)
          })

        provider.on('connect', this.metamaskEventConnected.bind(this))
        provider.on('disconnect', this.metamaskEventDisconnected.bind(this))
        provider.on(
          'accountsChanged',
          this.metamaskEventAccountsChanged.bind(this)
        )
        provider.on('chainChanged', this.metamaskEventChainChanged.bind(this))
        provider.on('message', this.metamaskEventMessage.bind(this))

        this.mmInstalled = true
      }
    },
    metamaskEventAccountsChanged(accounts) {
      if (accounts.length > 0) {
        this.accountAddress = accounts[0]
        // console.log('accountAddress', this.accountAddress)
      }
    },
    metamaskEventConnected(connectInfo) {
      console.log('metamaskEventConnected', connectInfo)
      // TODO ...
    },
    metamaskEventDisconnected(providerRpcError) {
      console.log('metamaskEventDisconnected', providerRpcError)
    },
    metamaskEventChainChanged(chainId) {
      // console.log('metamaskEventChainChanged', chainId)
      if (chainId !== this.chainId) {
        // TODO this.$store.commit('wallet/setV2ChainId', chainId)
        this.chainId = chainId
      }
    },
    metamaskEventMessage(msg) {
      console.log('metamaskEventMessage', msg)
      // TODO ...
    },
    // Принудительное подключение
    connectMetamaskAction() {
      const { ethereum } = window
      ethereum
        .request({ method: 'eth_requestAccounts' })
        .then(this.metamaskEventAccountsChanged.bind(this))
        .catch((err) => {
          if (err.code === 4001) {
            // EIP-1193 userRejectedRequest error
            // If this happens, the user rejected the connection request.
            console.log('Please connect to MetaMask.')
          } else {
            console.error(err)
          }
        })
    },
    // https://eips.ethereum.org/EIPS/eip-3085
    metamaskAddEthereumChain(chain) {
      const { ethereum } = window
      ethereum
        .request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: `0x${chain.chainId.toString(16)}`,
              chainName: chain.name, // Не обязательное поле. Желательно в api chainName сделать
              rpcUrls: [`${chain.entryPointsHttp}`],
              iconUrls: chain.iconUrls ? [`${chain.iconUrls}`] : '', // желательно ссылку на логотип png https...
              nativeCurrency: {
                name: chain.name,
                symbol: chain.symbol,
                decimals: 18,
              },
            },
          ],
        })
        .then(() => {
          this.$toast.add({
            severity: 'success',
            summary: 'success',
            detail: 'Сеть добавлена',
            group: 'info',
            life: 3000,
          })
        })
        .catch(() => {
          this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Ошибка при добавлении сети',
            group: 'info',
            life: 3000,
          })
        })
    },
    // https://eips.ethereum.org/EIPS/eip-747
    metamaskAddEthereumCoin(token) {
      const { ethereum } = window
      ethereum
        .request({
          method: 'wallet_watchAsset',
          params: {
            type: 'ERC20',
            options: {
              address: token.createdAddress,
              symbol: token.mnemonicEng,
              decimals: token.decimals ? token.decimals : 0,
              image: token.logoMain ? token.logoMain : '',
            },
          },
        })
        .then(() => {
          this.$toast.add({
            severity: 'success',
            summary: 'success',
            detail: 'Токен добавлен',
            group: 'info',
            life: 3000,
          })
        })
        .catch(() => {
          this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Ошибка при добавлении токена',
            group: 'info',
            life: 3000,
          })
        })
    },
  },
}
