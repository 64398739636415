<template>
  <div class="card">
    <Toast position="top-center" group="info" />
    <h5>Projects</h5>
    <ProgressBar
      v-if="loading"
      class="progress-bar"
      mode="indeterminate"
    />
    <DataTable
      v-show='!loading'
      :value="capitalList"
      data-key="id"
      v-model:wallets="capitalList"
      responsiveLayout="scroll"
      paginator
      lazy
      :rows="rows"
      :totalRecords="totalItems"
      :sortable="true"
      @page="setPage"
    >
      <Column header="Name" field="tokenBase.nameEng" style="width: 20%"/>
      <Column header="Currency" field="tokenQuote.mnemonicEng" />
      <Column header="Max Cap" field="maxCap">
        <template #body="slot">
          <OutputDecimals
            :decimals-value="slot.data.maxCap"
            :decimals="slot.data.tokenQuote.decimals"
          />
        </template>
      </Column>
      <Column header="Min Cap" field="minCap">
        <template #body="slot">
          <OutputDecimals
            :decimals-value="slot.data.minCap"
            :decimals="slot.data.tokenQuote.decimals"
          />
        </template>
      </Column>
      <Column header="Collected" field="currentCap">
        <template #body="slot">
          <OutputDecimals
            :decimals-value="slot.data.currentCap"
            :decimals="slot.data.tokenQuote.decimals"
          />
        </template>
      </Column>
      <Column header="Price" field="priceBase">
        <template #body="slot">
          <OutputDecimals
            :decimals-value="slot.data.priceBase"
            :decimals="slot.data.tokenQuote.decimals"
          />
        </template>
      </Column>
      <Column class="bg-white border-none">
        <template #body="slot">
          <div class="for-two-btn-container">
            <div class="absolute flex gap-1">
              <div data-help="Complete">
                <Button
                  icon="pi pi-lock"
                  class="p-button-rounded p-button-warning"
                  @click="showCloseDialog(slot.data)"
                />
              </div>
              <div data-help="Info">
                <Button
                  icon="pi pi-info-circle"
                  class="p-button-rounded p-button-warning"
                  @click="showInfoDialog(slot.data)"
                />
              </div>
              <div data-help="Remove">
                <Button
                  icon="pi pi-trash"
                  class="p-button-rounded p-button-danger"
                  @click="showDeleteDialog(slot.data)"
                />
              </div>
            </div>
          </div>
        </template>
      </Column>
    </DataTable>
  </div>

  <Dialog
    v-model:visible="closeDialog"
    header="Complete project?"
    :modal="true"
  >
    <div class="confirmation-content">
      <p class="text-center p-error" v-if="modifyCapital">
        Are you sure you want to complete?
      </p>
      <p class="text-center">
        <b>{{ modifyCapital.tokenBase.name }}</b>
      </p>
    </div>
    <template #footer>
      <Button
        label="No"
        icon="pi pi-times"
        class="p-button-text"
        @click="closeDialog = false"
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        class="p-button-text"
        @click="closeCapital"
      />
    </template>
  </Dialog>
  <Dialog
    v-model:visible="deleteDialog"
    header="Remove project?"
    :modal="true"
  >
    <div class="confirmation-content">
      <p class="text-center p-error" v-if="modifyCapital">
        Are you sure you want to delete?
      </p>
      <p class="text-center">
        <b>{{ modifyCapital.tokenBase.name }}</b>
      </p>
    </div>
    <template #footer>
      <Button
        label="No"
        icon="pi pi-times"
        class="p-button-text"
        @click="deleteDialog = false"
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        class="p-button-text"
        @click="deleteCapital"
      />
    </template>
  </Dialog>
  <Dialog
    v-model:visible="infoDialog"
    header="Information"
  >
    <div class="info-markup">
      <span>Name: {{ modifyCapital?.tokenBase?.nameEng }}</span>
      <span>Mnemonic: {{ modifyCapital?.tokenBase?.mnemonicEng }}</span>
      <span>Description: {{ modifyCapital?.descriptionEng }}</span>
      <span>Price:
        <OutputDecimals
          :decimals-value="modifyCapital?.priceBase"
          :decimals="modifyCapital?.tokenQuote?.decimals"
        />
        {{ modifyCapital?.tokenQuote?.mnemonicEng }}
      </span>
      <span>
        Date start: {{ formatDate(modifyCapital?.dateStart) }}
      </span>
      <span>
        Date end: {{ formatDate(modifyCapital?.dateEnd) }}
      </span>
    </div>
    <DataTable
      :value="[modifyCapital]"
      showGridlines
      class="mt-2"
    >
      <Column header="Hard cap" field="minCap">
        <template #body="slot">
          <OutputDecimals
            :decimals-value="slot.data?.minCap"
            :decimals="slot.data?.tokenQuote?.decimals"
          />
        </template>
      </Column>
      <Column header="Max cap" field="maxCap">
        <template #body="slot">
          <OutputDecimals
            :decimals-value="slot.data?.maxCap"
            :decimals="slot.data?.tokenQuote?.decimals"
          />
        </template>
      </Column>
      <Column header="Collected" field="currentCap">
        <template #body="slot">
          <OutputDecimals
            :decimals-value="slot.data?.currentCap"
            :decimals="slot.data?.tokenQuote?.decimals"
          />
        </template>
      </Column>
      <Column header="Company name" field="company.nameEng">
        <template #body="slot">
          {{ slot.data?.company?.nameEng }}
        </template>
      </Column>
    </DataTable>
  </Dialog>
</template>

<script>
import moment from 'moment'
import OutputDecimals from '@/components/edit/OutputDecimals'

export default {
  name: "Capital",
  components: { OutputDecimals },
  props: {
    id: {
      type: String,
      default: null,
    }
  },
  data() {
    return {
      loading: false,
      capitalList: [],
      modifyCapital: null,
      closeDialog: false,
      deleteDialog: false,
      infoDialog: false,
      page: 1,
      itemsPerPage: 30,
      rows: 30,
      totalItems: 10,
    }
  },
  async mounted() {
    await this.getCapital()
    if (this.id) {
      await this.getCapitalById();
    }
  },
  methods: {
    moment(date) {
      return moment(date)
    },
    setPage(event) {
      this.page = event.page + 1
      this.getCapital()
    },
    async getCapital() {
      this.loading = true;
      const headers = {
        accept: "application/ld+json",
        "Content-Type": "application/ld+json",
      }
      const params = {
        page: this.page,
        itemsPerPage: this.itemsPerPage,
        pagination: true,
      }
      try {
        const resp = await this.axios.get('api/au_capitals', {
          headers,
          params,
        })
        this.capitalList = resp.data['hydra:member']
        this.totalItems = resp.data['hydra:totalItems']
      }
      catch (e) {
        this.showError('Error in au_capitals api call')
      }
      this.loading = false;
    },
    async getCapitalById() {
      this.loading = true;
      try {
        const resp = await this.axios.get(`/api/au_capitals/${this.id}`);
        this.showInfoDialog(resp.data);
      }
      catch (e) {
        this.showError("Error in au_capitals id api call");
      }
      this.loading = false;
    },
    showCloseDialog(value) {
      this.modifyCapital = value
      this.closeDialog = true
    },
    showDeleteDialog(value) {
      this.modifyCapital = value
      this.deleteDialog = true
    },
    showInfoDialog(value) {
      this.modifyCapital = value;
      this.infoDialog = true;
    },
    formatDate(date) {
      return moment(date).format("HH:mm:ss DD.MM.YYYY")
    },
    closeCapital() {
      this.closeDialog = false
      let params = {
        user: `/api/users/${this.$auth.user().id}`,
        auCapital: `/api/au_capitals/${this.modifyCapital.id}`,
        type: 'token_capital_close',
        description: '',
        value: '0',
        address: '',
        isTask: true,
      }
      try {
        this.axios.post('api/token_events', params)
        this.showMessage('Project closed successfully')
      } catch (e) {
        this.showError('Error in closed project')
      }
    },
    deleteCapital() {
      this.deleteDialog = false
      try {
        this.axios.delete(`api/au_capitals/${this.modifyCapital.id}`)
        this.capitalList.splice(this.modifyCapital.displayId, 1)
        this.showMessage('Project deleted successfully')
      } catch (e) {
        this.showError('Error in deleted project')
      }
    },

    select(event) {
      this.image = event.files[0]
    },

    showError(message) {
      this.$toast.add({
        severity: 'error',
        summary: 'Error',
        detail: message,
        group: 'info',
        life: 3000,
      })
    },

    showMessage(message) {
      this.$toast.add({
        severity: 'success',
        summary: 'Successful',
        detail: message,
        group: 'info',
        life: 3000,
      })
    }
  },
}
</script>

<style scoped>
[data-help] {
  position: relative;
}
[data-help]::after {
  content: attr(data-help);
  position: absolute;
  left: 7px;
  top: 0;
  pointer-events: none;
  opacity: 0;
  transition: 0.5s;
}
[data-help]:hover::after {
  opacity: 1;
  top: -1.5em;
}
.info-markup {
  display: grid;
  grid-template-columns: 400px 400px;
  row-gap: 20px
}
</style>
